import { holesky } from "viem/chains";

export const environment = {
  USER_BASE_URL:'http://54.162.203.46/network-smetx-user-dev',
  API_BASE_URL:'https://devapi.sunwavecoin.com/api/v1/admin/',
  PROVIDER: 'https://ethereum-holesky.blockpi.network/v1/rpc/public',
  CONTRACT_ADDRESS:'0xF6f5f264d8E020C424c5D9aEE2F5014B4e7760Af',
  USDT_TOKEN: '0x1bc31DAf29A886C43Fd96363714f7952443A23af',
  SUPPORTED_CHAINS: [holesky],
  SUPPORTED_CHAINID: '17000',
  TRANSACTION_HASH_BASEURL: 'https://holesky.etherscan.io/tx',
  WALLET_CONNECT_PROJECT_ID: '66932ffba082b546f31afb2089524bb9',
  APP_NAME: 'Sunwave',
  APP_DESCRIPTION: 'Sunwave Public Sale',
  APP_URL: 'https://sunwave.com',
  VESTING_CONTRACT: '0xef8E476778EBc607087b78370bbEEb07AB732dE5',
  CHAIN_ID:17000
};
